'use client';

import type {
  TooltipContentProps,
  TooltipProps,
} from '@radix-ui/react-tooltip';
import * as React from 'react';
import * as TooltipPrimitive from '~/modules/ui/primitives/tooltip';

interface TolaTooltipProps
  extends Omit<TooltipContentProps, 'content' | 'asChild'> {
  children: React.ReactNode;
  content: string;
  defaultOpen?: TooltipProps['defaultOpen'];
  open?: TooltipProps['open'];
  onOpenChange?: TooltipProps['onOpenChange'];
  delayDuration?: TooltipProps['delayDuration'];
  disableHoverableContent?: TooltipProps['disableHoverableContent'];
  disabled?: boolean;
}

function Tooltip({
  defaultOpen,
  open,
  onOpenChange,
  delayDuration,
  disableHoverableContent,
  content,
  children,
  ...props
}: TolaTooltipProps) {
  return (
    <TooltipPrimitive.Tooltip
      delayDuration={delayDuration}
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.TooltipTrigger
        aria-label={content}
        disabled={props.disabled}
        asChild
      >
        {children}
      </TooltipPrimitive.TooltipTrigger>
      <TooltipPrimitive.TooltipContent {...props}>
        {content}
      </TooltipPrimitive.TooltipContent>
    </TooltipPrimitive.Tooltip>
  );
}

export { Tooltip };
