import { ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from '~/modules/ui/components/error-boundary';
import { GlobalNavigation } from '~/modules/ui/components/global-navigation';
import { NoSSRSuspense } from '~/modules/ui/components/no-ssr-suspense';
import {
  AppView,
  AppViewColumn,
  AppViewContent,
  AppViewSidebar,
} from '~/modules/ui/layouts/app-view';

export function DefaultAppView({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof AppViewColumn>) {
  return (
    <AppView>
      <AppViewContent>
        <AppViewSidebar>
          <GlobalNavigation />
        </AppViewSidebar>
        <AppViewColumn {...props}>
          <ErrorBoundary>
            <NoSSRSuspense>{children}</NoSSRSuspense>
          </ErrorBoundary>
        </AppViewColumn>
      </AppViewContent>
    </AppView>
  );
}
