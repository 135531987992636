'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import type { ElementRef } from 'react';
import { type ComponentPropsWithoutRef, Fragment, forwardRef } from 'react';
import { dialogOverlayClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { Button } from '~/modules/ui/primitives/button';
import { CloseIcon } from '~/modules/ui/primitives/icon';
import { ScrollArea } from '~/modules/ui/primitives/scroll-area';
import { HStack, VStack } from '~/modules/ui/primitives/stack';

export const Sheet = DialogPrimitive.Root;

export const SheetTrigger = DialogPrimitive.Trigger;

export const SheetClose = DialogPrimitive.Close;

export const SheetPortal = DialogPrimitive.Portal;

export const SheetOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(function SheetOverlayComponent({ className, ...props }, forwardedRef) {
  return (
    <DialogPrimitive.Overlay
      ref={forwardedRef}
      className={cn(dialogOverlayClassNames, className)}
      {...props}
    />
  );
});

interface SheetPanelProps
  extends ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  withPortal?: boolean;
  scrollAreaClassName?: string;
  closeButtonClassName?: string;
  side?: 'right' | 'left';
}

export const SheetPanel = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  SheetPanelProps
>(function SheetPanelComponent(
  {
    className,
    children,
    side = 'right',
    onInteractOutside,
    withPortal = true,
    scrollAreaClassName,
    closeButtonClassName,
    ...props
  },
  forwardedRef,
) {
  const Comp = withPortal ? DialogPrimitive.Portal : Fragment;

  return (
    <Comp>
      <SheetOverlay />
      <DialogPrimitive.Content
        ref={forwardedRef}
        className={cn(
          'zoom-in-100 fixed inset-x-1 inset-y-1 z-50 flex flex-col overflow-hidden rounded-3xl bg-surface-muted transition will-change-transform',
          'data-[state=closed]:animate-out data-[state=open]:animate-in data-[state]:duration-400 data-[state]:ease-drawer',
          'sm:w-full sm:max-w-xl',
          side === 'right'
            ? 'data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full sm:left-auto'
            : 'data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full sm:right-auto',
          className,
        )}
        onInteractOutside={(event) => {
          // Note: duplicated logic in dialog.tsx / sheet.tsx
          const { target } = event;
          if (
            target &&
            'tagName' in target &&
            target.tagName === 'COM-1PASSWORD-BUTTON'
          ) {
            // Prevent 1Password closing sheet.
            event.preventDefault();
          }
          if (
            target &&
            'closest' in target &&
            typeof target.closest === 'function' &&
            (target.closest('[class^="pac"]') ||
              target.closest('[class^="toast"]'))
          ) {
            // Prevent GoogleAutoComplete or Toasts closing sheet.
            event.preventDefault();
          }

          onInteractOutside?.(event);
        }}
        {...props}
      >
        <ScrollArea
          className={cn(
            'min-h-full grow [&_[data-radix-scroll-area-viewport]>div:first-child]:h-full',
            scrollAreaClassName,
          )}
        >
          <VStack className="min-h-full">{children}</VStack>
          <DialogPrimitive.Close asChild>
            <Button
              aria-label="Close sheet"
              className={cn('absolute top-4 right-4', closeButtonClassName)}
              intent="secondary"
            >
              <CloseIcon size="md" />
            </Button>
          </DialogPrimitive.Close>
        </ScrollArea>
      </DialogPrimitive.Content>
    </Comp>
  );
});

export function SheetContent({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      gap="8"
      className={cn(
        'w-full max-w-xl grow px-6 pt-16 pb-12 sm:px-12 sm:pt-24 sm:pb-16',
        className,
      )}
      {...props}
    >
      {children}
    </VStack>
  );
}

export function SheetHeader({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return <VStack className={cn('gap-y-2 md:gap-y-3', className)} {...props} />;
}

export function SheetTitle({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof DialogPrimitive.Title>) {
  return (
    <DialogPrimitive.Title className={cn('text-title', className)} {...props} />
  );
}

export function SheetDescription({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof DialogPrimitive.Description>) {
  return (
    <DialogPrimitive.Description
      className={cn('text-body text-foreground-secondary', className)}
      {...props}
    />
  );
}

export const SheetFooter = forwardRef<
  ElementRef<typeof HStack>,
  ComponentPropsWithoutRef<typeof HStack>
>(function SheetFooter(
  {
    className,
    alignItems = 'center',
    justifyContent = 'start',
    children,
    ...props
  }: ComponentPropsWithoutRef<typeof HStack>,
  ref,
) {
  return (
    <div className='sticky inset-x-0 bottom-0 border-foreground/10 border-t bg-surface-muted'>
      <HStack
        ref={ref}
        alignItems={alignItems}
        justifyContent={justifyContent}
        gap="3"
        className={cn('w-full max-w-xl p-6 sm:px-12 sm:py-6', className)}
        {...props}
      >
        {children}
      </HStack>
    </div>
  );
});
