import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { useOnMount } from '~/hooks/useOnMount';
import { LoadingIndicator } from '~/modules/ui/primitives/loading-indicator';

interface RedirectProps extends PropsWithChildren {
  href: LinkProps['href'];
}

/**
 * Isomorphic redirect to another page.
 * Optionally takes `children` to display a client-side loading state whilst doing the redirect.
 */
// `pathname` is a string and not `Route` so it can be used in `_redirectTo`
const Redirect: React.FC<RedirectProps> = (props) => {
  const router = useRouter();

  // Client-side redirect
  useOnMount(() => {
    void router.replace(props.href);
  });

  return <>{props.children}</>;
};

export const RedirectWithSpinner = (props: RedirectProps) => {
  return (
    <div className="flex h-fill w-full flex-col items-center justify-center">
      <Redirect {...props}>
        <LoadingIndicator />
      </Redirect>
    </div>
  );
};
