import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const DiamondIcon = createIcon('DiamondIcon', [
  [
    'path',
    {
      d: 'M8.875 4.75h6.25m-6.25 0-4.125 5.5m4.125-5.5L12 10.25m-7.25 0 7.25 9 7.25-9m-14.5 0H12m7.25 0-4.125-5.5m4.125 5.5H12m3.125-5.5L12 10.25',
      key: 'a3crw5',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default DiamondIcon;
