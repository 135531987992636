import { Route } from 'nextjs-routes';
import type { ComponentPropsWithoutRef } from 'react';
import { z } from 'zod';
import { viewerApi } from '~/generated/trpc/viewer';
import { useViewer } from '~/hooks/useOrg';
import { useZodForm } from '~/hooks/useZodForm';
import { NotBankDisclaimer } from '~/modules/ui/components/legal';
import { RedirectWithSpinner } from '~/modules/ui/components/redirect';
import { SupportButton } from '~/modules/ui/components/support-button';
import { cn } from '~/modules/ui/cva';
import { DefaultAuthView } from '~/modules/ui/layouts/auth-view';
import {
  AuthViewContentHeader,
  AuthViewDescription,
  AuthViewTitle,
} from '~/modules/ui/layouts/auth-view';
import { LinkButton, SubmitButton } from '~/modules/ui/primitives/button';
import { Form } from '~/modules/ui/primitives/form';
import { ShareIcon } from '~/modules/ui/primitives/icon';
import { VStack } from '~/modules/ui/primitives/stack';

/**
 * Anything in here has to be approved by the Legal team at Lithic and Patriot
 * bank. So if you change anything just be aware it will result in a potential
 * copy review by them.
 */

function ExternalLink({
  href,
  className,
  children,
}: ComponentPropsWithoutRef<typeof LinkButton>) {
  return (
    <LinkButton
      rel="noreferrer"
      target="_blank"
      intent="ghost"
      className={cn('border hover:border-intent-secondary', className)}
      href={href}
    >
      <span>{children}</span>
      <ShareIcon />
    </LinkButton>
  );
}

/**
 * This component acts like a middleware which checks if the organization type is supported
 * by our main app and redirects to the correct page accordingly.
 * i.e. This is used to protect all `/org/*` routes.
 */
export function SupportedAppOrganizationGuard(props: {
  children: React.ReactNode;
}) {
  const viewer = useViewer();
  const { organization } = viewer;
  const form = useZodForm({
    schema: z.object({
      agree: z.boolean().default(true),
    }),
  });
  const agreeMutation = viewerApi.agree.useMutation();

  if (organization.status === 'SUSPENDED') {
    return (
      <DefaultAuthView options={{ showFooterWordmark: false }}>
        <AuthViewContentHeader>
          <AuthViewTitle>
            This organization has been temporarily locked
          </AuthViewTitle>
          <AuthViewDescription>
            Please contact customer support for assistance.
          </AuthViewDescription>
        </AuthViewContentHeader>

        <SupportButton className="self-start" />
      </DefaultAuthView>
    );
  }

  // A user only needs to agree on the first organization they click through.
  // If per-organization agreements are required, move this flag/field to the
  // organization table.
  if (viewer.agreementAt === null) {
    return (
      <DefaultAuthView options={{ showFooterWordmark: false }} gap="6">
        <AuthViewContentHeader>
          <AuthViewTitle>We've updated our Terms of Service</AuthViewTitle>
          <AuthViewDescription>
            We've updated our Terms of Service and other agreements. Please
            review the following agreements and if you agree press{' '}
            <span className="text-foreground">I agree</span> to continue
          </AuthViewDescription>
        </AuthViewContentHeader>

        <VStack gap="2" alignItems="start">
          <ExternalLink href={'/legal/terms' as unknown as Route}>
            Terms of service
          </ExternalLink>

          <ExternalLink href={'/legal/privacy' as unknown as Route}>
            Privacy policy
          </ExternalLink>

          <ExternalLink href={'/legal/originating' as unknown as Route}>
            Origination agreement
          </ExternalLink>

          <ExternalLink href={'/legal/business-banking' as unknown as Route}>
            Business banking agreement
          </ExternalLink>
        </VStack>

        <NotBankDisclaimer />

        <Form
          form={form}
          onSubmit={async () => await agreeMutation.mutateAsync()}
        >
          <SubmitButton>I agree</SubmitButton>
        </Form>
      </DefaultAuthView>
    );
  }

  switch (organization.type) {
    case 'COMPANY':
      return props.children;
    case 'LIGHT_ORGANIZATION':
      return <RedirectWithSpinner href="/org" />;
    case 'SOLE_PROPRIETOR':
      return (
        <DefaultAuthView
          options={{ showFooterWordmark: false }}
          gap="8"
          data-test-id="supported-app-organization-guard-sole-proprietor"
        >
          <AuthViewContentHeader>
            <AuthViewTitle>We&apos;re not quite ready for you.</AuthViewTitle>
            <AuthViewDescription>
              Right now we&apos;re only helping US based companies who are
              incorporated. We&apos;ll reach out via email once we start helping
              sole proprietorship based companies.
            </AuthViewDescription>
            <AuthViewDescription>
              If you&apos;d like to inquire about another business, or you think
              there has been a mistake, please contact support.
            </AuthViewDescription>
          </AuthViewContentHeader>

          <SupportButton className="self-start" />
        </DefaultAuthView>
      );
  }
}
