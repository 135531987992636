import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { HexColorPicker } from 'react-colorful';
import { cn } from '~/modules/ui/cva';

export type ColorPickerProps = React.ComponentProps<typeof HexColorPicker>;

function ColorPicker({ ...props }: ColorPickerProps) {
  return (
    <Slot
      className={cn(
        '[.react-colorful&]:aspect-square [.react-colorful&]:h-auto [.react-colorful&]:w-full [.react-colorful&]:min-w-48',
        // '[.react-colorful__saturation&]:',
        // '[.react-colorful__hue&]:',
        // '[.react-colorful__hue-pointer&]:',
      )}
    >
      <HexColorPicker {...props} />
    </Slot>
  );
}
ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
