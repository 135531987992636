import * as React from 'react';
import { cn } from '~/modules/ui/cva';
import { HStack } from '~/modules/ui/primitives/stack';

function Object({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      className={cn(
        'min-w-0 max-w-full [&>svg]:size-6 [&>svg]:text-foreground-secondary',
        className,
      )}
      alignItems="center"
      gap="2"
      {...props}
    />
  );
}

interface ObjectTitleProps extends React.HTMLAttributes<HTMLSpanElement> {
  fallback?: string;
}

function ObjectTitle({ className, fallback, ...props }: ObjectTitleProps) {
  if (!props.children && fallback) {
    return (
      <span className="text-foreground-secondary/50 leading-6">{fallback}</span>
    );
  }

  return (
    <span className={cn('text-foreground leading-6', className)} {...props} />
  );
}

function ObjectDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn('text-foreground-secondary leading-6', className)}
      {...props}
    />
  );
}

export { Object, ObjectTitle, ObjectDescription };
