import { ExternalToast, Toaster as Sonner, toast } from 'sonner';
import { cn } from '~/modules/ui/cva';
import { Button } from '~/modules/ui/primitives/button';
import {
  AlertIcon,
  CheckmarkIcon,
  CloseIcon,
  InformationIcon,
} from '~/modules/ui/primitives/icon';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { assertUnreachable } from '~/utils/assertUnreachable';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({
  position = 'top-right',
  offset = 24,
  gap = 8,
  ...props
}: ToasterProps) => {
  return (
    <Sonner
      className="toaster group select-none"
      position={position}
      offset={offset}
      gap={gap}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            'bg-surface w-full rounded-xl p-4 ring-[0.5px] ring-border flex flex-row overflow-hidden pointer-events-auto',
        },
      }}
      {...props}
    />
  );
};

interface ToastOptions extends ExternalToast {
  intent?: 'info' | 'success' | 'error';
  description?: string | React.ReactNode;
}

// Sonner has some great defaults, but "magic" of the internal html doesn't quite give
// us enough freedom. This is the most basic implementation, but as we design usecases
// we can implement super nice custom toasts for things like success/warning/etc
// following the existing api.
function showToast(
  title: string,
  { description, intent = 'info', ...opts }: ToastOptions,
) {
  return toast.custom(
    (toastId) => (
      <HStack
        gap="3"
        className="w-full"
        alignItems="start"
        data-test-id={`toast-${intent}`}
      >
        <VStack
          alignItems="center"
          justifyContent="center"
          className={cn('size-6 shrink-0 rounded-md', {
            'bg-surface-muted': intent === 'info',
            'bg-accent-orange/30': intent === 'error',
            'bg-accent-teal': intent === 'success',
          })}
        >
          {(() => {
            switch (intent) {
              case 'info':
                return (
                  <InformationIcon className="text-foreground-secondary" />
                );
              case 'error':
                return <AlertIcon className="text-accent-orange-secondary" />;
              case 'success':
                return <CheckmarkIcon className="text-accent-teal-secondary" />;
              default:
                return assertUnreachable(intent);
            }
          })()}
        </VStack>

        <VStack className='w-full grow'>
          <div className='text-body text-foreground'>{title}</div>
          {description && (
            <div className='text-body text-foreground-secondary'>
              {description}
            </div>
          )}
        </VStack>
        <div className='relative size-6'>
          <Button
            size="sm"
            intent="ghost"
            onClick={() => toast.dismiss(toastId)}
            className='-top-0.5 -left-0.5 absolute'
            data-test-id="toast-close"
          >
            <CloseIcon />
          </Button>
        </div>
      </HStack>
    ),
    opts,
  );
}

function showInfoToast(title: string, options?: Omit<ToastOptions, 'intent'>) {
  return showToast(title, { ...options, intent: 'info' });
}

function showErrorToast(title: string, options?: Omit<ToastOptions, 'intent'>) {
  return showToast(title, { ...options, intent: 'error' });
}

function showSuccessToast(
  title: string,
  options?: Omit<ToastOptions, 'intent'>,
) {
  return showToast(title, { ...options, intent: 'success' });
}

function dismissToast(id: string | number) {
  return toast.dismiss(id);
}

export {
  Toaster,
  showToast,
  showInfoToast,
  showErrorToast,
  showSuccessToast,
  dismissToast,
  type ToastOptions,
};
