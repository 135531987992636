import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ArrowUpRightCircleIcon = createIcon('ArrowUpRightCircleIcon', [
  [
    'path',
    {
      d: 'M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z',
      key: 'ehjvnu',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M14.25 13.25V9.75H10.75',
      key: '1epjc4',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M14 10L9.75 14.25',
      key: '1ii7g5',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ArrowUpRightCircleIcon;
