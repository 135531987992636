import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { VariantProps, cva } from '~/modules/ui/cva';

const pillVariants = cva({
  base: [
    'inline-flex h-6 truncate whitespace-pre rounded-full px-2 text-body leading-6',
    // when pill is inside an <a> tag, show pointer cursor
    'cursor-default [a_&]:cursor-pointer',
  ],
  variants: {
    intent: {
      primary: 'bg-surface text-foreground',
      secondary: 'bg-surface-muted text-foreground-secondary',
      inverse: 'bg-surface-inverse text-foreground-inverse',
      warning: 'bg-intent-warning text-intent-warning-foreground',
      danger: 'bg-intent-danger text-intent-danger-foreground',
    },
  },
  defaultVariants: {
    intent: 'secondary',
  },
});

type PillVariants = VariantProps<typeof pillVariants>;

interface PillProps
  extends React.ComponentPropsWithoutRef<'span'>,
    PillVariants {
  asChild?: boolean;
}

const Pill = React.forwardRef<React.ElementRef<'span'>, PillProps>(
  ({ asChild, children, className, intent, ...props }, forwardedRef) => {
    const Comp = asChild ? Slot : 'span';

    return (
      <Comp
        ref={forwardedRef}
        className={pillVariants({ intent, className })}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);
Pill.displayName = 'Pill';

export { Pill };
