'use client';

import { Slot } from '@radix-ui/react-slot';
import Link, { LinkProps } from 'next/link';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { AnyUseZodFormReturn } from '~/hooks/useZodForm';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { type VariantProps, cn, cva } from '~/modules/ui/cva';
import {
  CheckmarkIcon,
  SpinnerIcon,
  WarningTriangleIcon,
} from '~/modules/ui/primitives/icon';

const buttonVariants = cva({
  base: [
    'relative inline-flex shrink-0 select-none items-center overflow-hidden whitespace-pre rounded-full leading-none transition-colors duration-300 ease-in-out',
    'bg-gradient-to-t from-transparent to-foreground-inverse/10',
    focusRingClassNames,
    'disabled:cursor-not-allowed [a&]:data-[disabled=true]:pointer-events-none',
  ],
  variants: {
    intent: {
      primary:
        'bg-intent-primary text-intent-primary-foreground hover:bg-intent-primary-hover data-[disabled]:bg-intent-primary data-[disabled]:text-intent-primary-foreground/50 [&>svg:not(:only-child)]:opacity-70',
      secondary:
        'bg-intent-secondary text-intent-secondary-foreground hover:bg-intent-secondary-hover data-[disabled]:bg-intent-secondary data-[disabled]:text-intent-secondary-foreground/50 [&>svg:not(:only-child)]:opacity-50',
      ghost:
        'bg-none bg-transparent text-foreground-secondary hover:bg-intent-secondary hover:text-foreground data-[disabled]:bg-transparent data-[disabled]:text-foreground-secondary/40',
      danger:
        'bg-intent-danger text-intent-primary-foreground hover:bg-intent-danger-hover data-[disabled]:bg-intent-danger data-[disabled]:text-intent-primary-foreground/50 [&>svg:not(:only-child)]:opacity-70',
    },
    size: {
      sm: 'text-body [&_svg]:size-5',
      md: 'text-body [&_svg]:size-6',
      lg: 'text-subtitle [&_svg]:size-6',
    },
    status: {
      idle: '',
      loading: '',
      success: '',
      error: '',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
    status: 'idle',
  },
});

type ButtonVariants = VariantProps<typeof buttonVariants>;
type ButtonLike<T> = T & ButtonVariants;

type BaseButtonProps = ButtonLike<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    asChild?: boolean;
  }
>;
const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ intent, size, status, asChild, disabled, className, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const isDisabled = disabled || status !== 'idle';

    return (
      <Comp
        className={cn(buttonVariants({ intent, size, status }), className)}
        ref={ref}
        data-intent={intent}
        {...(isDisabled ? { ['data-disabled']: true, disabled: true } : {})}
        {...props}
      />
    );
  },
);
BaseButton.displayName = 'BaseButton';

const buttonInnerVariants = cva({
  base: ['flex w-full flex-nowrap items-center text-left text-start'],
  variants: {
    intent: {
      primary: '',
      secondary: '',
      ghost: '',
      danger: '',
    },
    size: {
      sm: ['h-7 gap-2 px-3', 'has-[svg:only-child]:px-1'],
      md: ['h-10 gap-2 px-4', 'has-[svg:only-child]:px-2'],
      lg: ['h-12 gap-3 px-5', 'has-[svg:only-child]:px-3'],
    },
    status: {
      idle: '',
      loading: '',
      success: '',
      error: '',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
    status: 'idle',
  },
});

type BaseButtonInnerProps = ButtonLike<React.HTMLAttributes<HTMLSpanElement>>;

function BaseButtonInner({
  intent,
  size,
  status,
  className,
  ...props
}: BaseButtonInnerProps) {
  return (
    <span
      className={cn(buttonInnerVariants({ intent, size, status }), className)}
      {...props}
    />
  );
}

const buttonStatusIndicatorVariants = cva({
  base: [
    '-inset-1 absolute flex items-center justify-center bg-inherit text-inherit',
  ],
  variants: {
    intent: {
      primary: '',
      secondary: '',
      ghost: 'bg-intent-secondary text-intent-secondary-foreground',
      danger: '',
    },
    size: {
      sm: '',
      md: '',
      lg: '',
    },
    status: {
      idle: '',
      loading: '',
      success: '',
      error: '',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
    status: 'idle',
  },
});

function BaseButtonStatusIcon({
  status,
}: { status: ButtonVariants['status'] }) {
  switch (status) {
    case 'idle':
      return null;
    case 'error':
      return <WarningTriangleIcon />;
    case 'loading':
      return <SpinnerIcon className="animate-spin" />;
    case 'success':
      return <CheckmarkIcon />;
  }
}

type BaseButtonStatusProps = ButtonLike<
  Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'>
>;

function BaseButtonStatus({
  intent,
  size,
  status,
  className,
  ...props
}: BaseButtonStatusProps) {
  if (status === 'idle') {
    return null;
  }

  return (
    <span
      className={cn(
        buttonStatusIndicatorVariants({ intent, size, status }),
        className,
      )}
      {...props}
      aria-hidden
    >
      <BaseButtonStatusIcon status={status} />
    </span>
  );
}

type ButtonProps = ButtonLike<React.ButtonHTMLAttributes<HTMLButtonElement>>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { intent = 'primary', size = 'md', status = 'idle', children, ...props },
    ref,
  ) => {
    const sharedProps = { intent, size, status };

    return (
      <BaseButton
        ref={ref}
        // Prevent the button from submitting a form
        type="button"
        {...sharedProps}
        {...props}
      >
        <BaseButtonStatus {...sharedProps} />
        <BaseButtonInner {...sharedProps}>{children}</BaseButtonInner>
      </BaseButton>
    );
  },
);
Button.displayName = 'Button';

type LinkButtonProps = ButtonLike<
  Omit<LinkProps, 'href'> & {
    disabled?: boolean;
    href: LinkProps['href'] | string;
    /**
     * Force the button to be a full page navigation
     */
    forceExternal?: true;
  }
>;

const LinkButton = React.forwardRef<
  React.ElementRef<typeof Link>,
  LinkButtonProps
>(
  (
    {
      intent = 'primary',
      size = 'md',
      status = 'idle',
      forceExternal,
      className,
      children,
      disabled,
      href,
      ...props
    },
    forwardedRef,
  ) => {
    const isExternal =
      forceExternal || (typeof href === 'string' && href.startsWith('http'));
    const Comp = !isExternal ? Link : 'a';
    const sharedProps = { intent, size, status };
    const isDisabled = disabled || status !== 'idle';
    const propsToPass = { ...props, ref: forwardedRef };

    return (
      <BaseButton
        className={className}
        disabled={isDisabled}
        {...sharedProps}
        asChild
      >
        {/* biome-ignore lint/suspicious/noExplicitAny: Ignore because it could be a Link or simple anchor */}
        <Comp href={href as any} {...propsToPass}>
          <BaseButtonStatus {...sharedProps} />
          <BaseButtonInner {...sharedProps}>{children}</BaseButtonInner>
        </Comp>
      </BaseButton>
    );
  },
);
LinkButton.displayName = 'LinkButton';

interface SubmitButtonProps extends Omit<ButtonProps, 'status' | 'form'> {
  /**
   * Reset the status from success or error to idle after a timeout.
   * Use `false` or `0` to disable
   * @default 2_000
   */
  statusTimeoutMs?: number | false;

  /**
   * If you are using `SubmitButton` outside of a `Form`, you must provide the result from `useZodForm()` here
   */
  form?: AnyUseZodFormReturn;
}

const SubmitButton = React.forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ children, statusTimeoutMs = 2_000, form, ...props }, ref) => {
    const formContext = useFormContext();
    const [status, setStatus] =
      React.useState<ButtonVariants['status']>('idle');

    const formState = form?.formState ?? formContext.formState;

    const showLoading = formState.isSubmitting;
    const showSuccess =
      formState.isSubmitted && formState.isSubmitSuccessful && !showLoading;
    const showError =
      formState.isSubmitted &&
      !formState.isValid &&
      !showLoading &&
      !form?.formState.errors.root?.abort;

    React.useEffect(() => {
      if (showLoading) {
        setStatus('loading');
      } else if (showSuccess) {
        setStatus('success');
      } else if (showError) {
        setStatus('error');
      } else {
        setStatus('idle');
      }
    }, [showLoading, showSuccess, showError]);

    // Reset status to idle on success after a success or error
    React.useEffect(() => {
      if (!statusTimeoutMs) {
        return;
      }
      const timeoutStatuses: (typeof status)[] = ['error', 'success'];
      if (!timeoutStatuses.includes(status)) {
        return;
      }
      const timeout = setTimeout(() => {
        setStatus('idle');
      }, statusTimeoutMs);

      return () => clearTimeout(timeout);
    }, [status, statusTimeoutMs]);

    return (
      <Button
        ref={ref}
        {...props}
        type="submit"
        {...(form ? { form: form.uniqueId } : {})}
        status={status}
      >
        {children}
      </Button>
    );
  },
);
SubmitButton.displayName = 'SubmitButton';

type SubmitButtonLike<T> = Omit<T, 'form' | 'status'> & SubmitButtonProps;

function buttonStatusFromMutation(args: {
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
}): Required<ButtonVariants['status']> {
  if (args.isPending) {
    return 'loading';
  }

  if (args.isError) {
    return 'error';
  }

  if (args.isSuccess) {
    return 'success';
  }

  return 'idle';
}

export {
  Button,
  LinkButton,
  SubmitButton,
  buttonVariants,
  buttonStatusFromMutation,
  type ButtonLike,
  type ButtonVariants,
  type SubmitButtonLike,
};
