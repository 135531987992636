import type { ComponentPropsWithoutRef } from 'react';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { PaymentsByTola } from '~/modules/ui/primitives/wordmark';

export function AppView({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <div className="bg-background">
      <VStack
        className={cn(
          'relative min-h-screen w-full gap-4 bg-background p-4 md:gap-6 md:p-6',
          className,
        )}
        {...props}
      >
        {children}
      </VStack>
    </div>
  );
}

export function AppViewContent({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      className={cn(
        'grow gap-x-4 transition-[gap] md:gap-x-6 lg:gap-x-10',
        className,
      )}
      {...props}
    >
      {children}
    </HStack>
  );
}

export function AppViewColumn({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      gap="6"
      className={cn('relative w-1/2 flex-auto grow', className)}
      {...props}
    >
      {children}
    </VStack>
  );
}

export function AppViewColumnActions({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      justifyContent="end"
      className={cn(
        'pointer-events-none sticky top-4 z-30 md:top-6 [&>*]:pointer-events-auto',
        className,
      )}
      {...props}
    >
      {children}
    </HStack>
  );
}

export function AppViewSidebar({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        'fixed z-30 flex shrink-0 flex-col items-start',
        'md:relative md:max-w-64',
        className,
      )}
      {...props}
    >
      <div className="sticky top-4 max-w-full md:top-6">{children}</div>
    </div>
  );
}

export function AppViewFooter({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        'flex flex-col-reverse items-start gap-y-5 py-2',
        'sm:flex-row sm:items-center sm:justify-between',
        className,
      )}
      {...props}
    >
      <PaymentsByTola />
      <HStack alignItems="center" gap="6" className="sm:gap-8">
        <AppViewFooterLink href="https://usetola.com/legal/terms-of-service">
          Terms of Service
        </AppViewFooterLink>
        <AppViewFooterLink href="https://usetola.com/legal/privacy-policy">
          Privacy policy
        </AppViewFooterLink>
      </HStack>
    </div>
  );
}

export function AppViewFooterLink({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={cn(
        focusRingClassNames,
        'focus-visible:rounded-sm focus-visible:ring-offset-background',
        'text-foreground-secondary leading-4 decoration-1 underline-offset-2 hover:underline',
        className,
      )}
      {...props}
    />
  );
}
