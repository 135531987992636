import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import type {
  ControllerProps,
  FieldPath,
  FieldValues,
  SubmitHandler,
} from 'react-hook-form';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { UseZodFormReturn } from '~/hooks/useZodForm';
import { cn } from '~/modules/ui/cva';
import { FormAbortError, toastErrorHandler } from '~/modules/ui/form-error';
import { Label } from '~/modules/ui/primitives/label';
import { Pill } from '~/modules/ui/primitives/pill';
import { createLogger } from '~/utils/logger';
import { isObject } from '~/utils/object';
import { isString } from '~/utils/utility';

const logger = createLogger('Form');

const getMessageFromCause = (cause: unknown): string => {
  if (cause instanceof Error) {
    // 99% case, but some libraries throw non-Error objects
    return cause.message;
  }
  if (isObject(cause)) {
    return isString(cause.message)
      ? // object is "ErrorLike"
        cause.message
      : JSON.stringify(cause);
  }
  return String(cause);
};

type SuccessHandlerOpts<TInput, TOutput> = {
  input: TInput;
  output: TOutput | undefined;
};

/**
 * A function that handles a successful form submission
 */
type SuccessHandler<TInput> = (
  opts: SuccessHandlerOpts<TInput, unknown>,
) => void;

/**
 * A function that handles a failed form submission
 */
type ErrorHandler = (e: unknown) => void;

interface FormProps<T extends FieldValues>
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  children?: React.ReactNode;
  className?: string;
  form: UseZodFormReturn<T>;
  onConfirm?: () => Promise<boolean> | boolean;
  onSubmit: SubmitHandler<T>;
  onSuccess?: SuccessHandler<T>;
  onError?: ErrorHandler;
}

const Form = <T extends FieldValues = FieldValues>(props: FormProps<T>) => {
  const {
    children,
    className,
    onConfirm,
    onSubmit,
    onSuccess,
    onError = toastErrorHandler,
    form,
    ...remainingProps
  }: typeof props = props;

  return (
    <FormProvider {...form}>
      <form
        // We have a unique id for the `<form>` so we can place submit button outside of the form
        id={form.uniqueId}
        className={className}
        onSubmit={(event) => {
          // prevent bubbling in case of modals with multiple forms
          event.stopPropagation();
          // prevent default form submission
          event.preventDefault();

          (async () => {
            // trigger form's validation, then confirm submit
            if (onConfirm) {
              // trigger validation before confirming submit
              const isValid = await form.trigger();
              if (isValid && !(await onConfirm())) {
                // user cancelled submit
                return;
              }
            }

            await form.handleSubmit(async (data, e) => {
              try {
                const output = await onSubmit(data as T, e);
                onSuccess?.({ input: data as T, output });
              } catch (cause) {
                // `handleSubmit()`'s callback may not reject, otherwise it gets stuck in loading state

                // https://react-hook-form.com/api/useform/seterror/

                if (cause instanceof FormAbortError) {
                  form.setError('root.abort', {
                    message: 'FormAbortError',
                    type: 'server',
                  });
                } else {
                  form.setError('root.server', {
                    message: getMessageFromCause(cause),
                    type: 'server',
                  });
                }

                onError(cause);
              }
            })(event);
          })().catch((error) => {
            // Should never happen, but just in case
            logger.error('Error in form submission', { error }, error);
          });
        }}
        {...remainingProps}
      >
        {children}
      </form>
    </FormProvider>
  );
};

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const { id } = itemContext;
  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

const FormItem = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>((props, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <Slot ref={ref} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-destructive', className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

function FormHint({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'span'>) {
  const childIsString = typeof children === 'string';
  const Comp = childIsString ? Pill : Slot;

  return (
    <Comp
      className={cn(
        'ml-auto',
        {
          'text-caption leading-6': childIsString,
        },
        className,
      )}
      {...props}
    >
      {children}
    </Comp>
  );
}

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>((props, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      id={formItemId}
      ref={ref}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={Boolean(error)}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <span
      ref={ref}
      id={formDescriptionId}
      className={cn('text-caption text-foreground-secondary', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<'span'>
>(({ children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <span
      ref={ref}
      id={formMessageId}
      className="text-accent-orange-secondary"
      {...props}
    >
      {body}
    </span>
  );
});
FormMessage.displayName = 'FormMessage';

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormHint,
  FormMessage,
  useFormField,
};
