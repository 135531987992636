import type { SuspenseProps } from 'react';
import React, { Suspense, useEffect, useState } from 'react';
import { LoadingIndicator } from '~/modules/ui/primitives/loading-indicator';

function DefaultFallback() {
  return (
    <div className="grid size-full grow place-items-center">
      <LoadingIndicator className="text-foreground-secondary" />
    </div>
  );
}

/**
 * Whether the app has ever mounted.
 * This is used to determine whether to render the fallback on the first render.
 */
let hasEverMounted = false;

/**
 * Suspense that doesn't render on the server.
 * - On server, it always renders the fallback.
 * - On client, it renders the fallback only on the first render.
 */
function NoSSRSuspense(props: {
  children: React.ReactNode;
  /**
   * Fallback while loading.
   * @default <LoadingIndicator />
   */
  fallback?: SuspenseProps['fallback'];
}) {
  const [isMounted, setIsMounted] = useState(hasEverMounted);
  useEffect(() => {
    setIsMounted(true);
    hasEverMounted = true;
  }, []);
  const fallback =
    props.fallback === undefined ? <DefaultFallback /> : props.fallback;

  return (
    <>{isMounted ? <Suspense {...props} fallback={fallback} /> : fallback}</>
  );
}

export { NoSSRSuspense };
