import { cn } from '~/modules/ui/cva';

export const NotBankDisclaimer = () => {
  return (
    <p className={cn('text-pretty text-body text-foreground-secondary')}>
      Tola Inc. is not a FDIC-insured institution. Banking services provided by
      Patriot Bank, N.A., Member FDIC.
    </p>
  );
};
