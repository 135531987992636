import { PopoverContent, PopoverPortal } from '@radix-ui/react-popover';
import { AnimatePresence, MotionConfig, m } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';
import useMeasure from 'react-use-measure';
import { useEventListener } from '~/hooks/useEventListener';
import { useIsMediumScreen } from '~/hooks/useMediaQuery';
import { useOnEscape } from '~/hooks/useOnEscape';
import { useOnOutsideInteraction } from '~/hooks/useOnOutsideInteraction';
import {
  GlobalNavigationContext,
  useGlobalNavigationCtx,
} from '~/modules/ui/components/global-navigation/global-navigation-context';
import { MenuComponentForName } from '~/modules/ui/components/global-navigation/global-navigation-list';
import { cn } from '~/modules/ui/cva';
import { Card } from '~/modules/ui/primitives/card';
import { MenuIcon } from '~/modules/ui/primitives/icon';
import { Popover, PopoverTrigger } from '~/modules/ui/primitives/popover';

import { Toolbar, ToolbarButton } from '~/modules/ui/primitives/toolbar';

function MenuComponent({ className }: { className?: string }) {
  const ctx = useGlobalNavigationCtx();
  const [ref, bounds] = useMeasure();

  const height = bounds.height === 0 ? 'auto' : bounds.height;
  const width = bounds.width === 0 ? 'auto' : bounds.width;

  return (
    <m.div
      animate={{
        height,
        width,
      }}
      initial={false}
      className={className}
    >
      <AnimatePresence
        mode="popLayout"
        initial={false}
        custom={ctx.direction.current}
      >
        <m.div
          key={ctx.visibleMenuState.name}
          variants={{
            initial: (direction: 1 | -1) => {
              return { x: `${110 * direction}%`, opacity: 0 };
            },
            active: { x: '0%', opacity: 1 },
            exit: (direction: 1 | -1) => {
              return { x: `${-110 * direction}%`, opacity: 0 };
            },
          }}
          initial="initial"
          animate="active"
          exit="exit"
          custom={ctx.direction.current}
        >
          <div ref={ref} className="w-fit max-w-64">
            <MenuComponentForName name={ctx.visibleMenuState.name} />
          </div>
        </m.div>
      </AnimatePresence>
    </m.div>
  );
}

function MobileMenuWrapper() {
  const ctx = useGlobalNavigationCtx();
  const router = useRouter();
  const [sheetOpen, setSheetOpen] = React.useState(false);

  const isDesktop = useIsMediumScreen();

  const onToggle = (open: boolean) => {
    setSheetOpen(open);
    if (!open) {
      ctx.onDismiss();
    }
  };

  useEventListener({
    type: 'resize',
    listener: () => onToggle(false),
    enabled: sheetOpen && isDesktop,
  });

  React.useEffect(() => {
    const handler = () => onToggle(false);

    if (!isDesktop) {
      router.events.on('routeChangeComplete', handler);
      return;
    }

    return router.events.off('routeChangeComplete', handler);
  }, [isDesktop, router.pathname]);

  return (
    <Toolbar intent="ghost" className="md:hidden">
      <Popover open={sheetOpen} onOpenChange={onToggle}>
        <PopoverTrigger asChild>
          <ToolbarButton intent="secondary">
            <MenuIcon />
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            side="bottom"
            align="start"
            sideOffset={-48}
            alignOffset={-8}
            className={cn(
              'relative z-[55] w-fit max-w-64 overflow-hidden rounded-3xl bg-surface-muted',
              'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[side=bottom]:slide-in-from-top-2 data-[state=closed]:animate-out data-[state=open]:animate-in',
            )}
            data-test-id="default-app-navigation-mobile"
          >
            <MenuComponent />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </Toolbar>
  );
}

function DesktopMenuWrapper() {
  const ctx = useGlobalNavigationCtx();
  const cardRef = React.useRef<HTMLDivElement>(null);

  const isDesktop = useIsMediumScreen();
  const canDismiss = ctx.routeMenuState.name !== ctx.visibleMenuState.name;

  useOnOutsideInteraction({
    ref: cardRef,
    type: 'pointerdown',
    listener: ctx.onDismiss,
    enabled: canDismiss && isDesktop,
  });

  useOnEscape({
    handler: ctx.onDismiss,
    enabled: canDismiss && isDesktop,
  });

  return (
    <Card
      variant="muted"
      className={cn(
        'relative overflow-hidden rounded-3xl p-0',
        'hidden md:block',
      )}
      ref={cardRef}
      data-test-id="default-app-navigation"
    >
      <MenuComponent />
    </Card>
  );
}

export function GlobalNavigation() {
  return (
    <MotionConfig transition={{ duration: 0.5, type: 'spring', bounce: 0 }}>
      <GlobalNavigationContext>
        <MobileMenuWrapper />
        <DesktopMenuWrapper />
      </GlobalNavigationContext>
    </MotionConfig>
  );
}
