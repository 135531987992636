import { cn } from '~/modules/ui/cva';

/* Common focus state classNames */
export const focusRingClassNames = cn(
  'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-surface',
);

/* Common form element classNames */
export const inputClassNames = cn(
  'flex h-10 w-full items-center rounded-lg border border-input bg-surface px-3 text-body text-foreground placeholder:text-body placeholder:text-foreground-secondary',
  'file:border-0 file:bg-transparent file:font-medium file:text-caption [input[type=file]&]:py-1.5',
  focusRingClassNames,
  'disabled:cursor-not-allowed disabled:bg-input/15 disabled:text-foreground-secondary/70',
  // Fixes clashing with global.css
  'focus:border-input',
);

/* Common popover classnames */
export const popoverClassNames = cn(
  'relative overflow-hidden rounded-lg bg-surface text-foreground shadow-popover',
);

export const menuContentClassNames = cn(
  popoverClassNames,
  'z-50 min-w-40 p-1',
  'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 data-[state=closed]:animate-out data-[state=open]:animate-in',
);

export const menuItemClassNames = cn(
  'relative flex cursor-pointer items-center gap-x-2 rounded-md px-2 py-1.5 text-body leading-6 transition-colors',
  'select-none outline-none focus:bg-surface-muted focus:text-foreground aria-selected:bg-surface-muted aria-selected:text-foreground',
  'aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
  '[&>svg]:text-foreground-secondary',
);

export const menuItemIndicatorClassNames = cn(
  'absolute left-2 flex size-6 items-center justify-center',
  '[&>svg]:size-5 [&>svg]:text-foreground-secondary',
);

export const menuSeparatorClassNames = cn('-mx-1 my-1 h-px bg-surface-muted');

export const menuLabelClassNames = cn(
  'px-2 py-1.5 text-caption text-foreground-secondary',
);

export const menuShortcutClassNames = cn(
  'ml-auto text-caption tracking-widest opacity-60',
);

/* Common Dialog
 * <Dialog />, <AlertDialog />, <Sheet />, etc.
 */

export const dialogOverlayClassNames = cn(
  'fixed inset-0 z-50 overflow-y-auto overflow-x-hidden bg-surface-inverse/10 outline-none backdrop-blur-[60px] backdrop-grayscale-50 will-change-auto',
  'grid place-items-center p-2 sm:p-8',
  'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=open]:animate-in data-[state]:duration-400 data-[state]:ease-drawer',
);

export const dialogContentClassNames = cn(
  // Sizing
  'relative max-h-full w-full max-w-full sm:max-w-lg',
  // Visual
  'grid gap-y-6 rounded-3xl bg-surface-muted px-5 pt-8 pb-5 sm:px-8 sm:pb-8',
  // Animation
  'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-top-4 data-[state=open]:slide-in-from-top-4 duration-200 data-[state=closed]:animate-out data-[state=open]:animate-in',
);
