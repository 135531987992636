import { useLayoutEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { viewerApi } from '~/generated/trpc/viewer';
import { useEventCallback } from '~/hooks/useEventCallback';
import { useOnMount } from '~/hooks/useOnMount';
import { useZodForm } from '~/hooks/useZodForm';
import { useAppViewer } from '~/modules/account/ui/AppContext';
import { DeveloperMfaVerify } from '~/modules/account/ui/DeveloperButtons';
import { cn } from '~/modules/ui/cva';
import { PhoneInputField } from '~/modules/ui/fields/phone-input-field';
import {
  AuthViewContentHeader,
  AuthViewDescription,
  AuthViewTitle,
  DefaultAuthView,
} from '~/modules/ui/layouts/auth-view';
import { SubmitButton } from '~/modules/ui/primitives/button';
import { Form, FormField } from '~/modules/ui/primitives/form';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '~/modules/ui/primitives/input-otp';
import { VStack } from '~/modules/ui/primitives/stack';
import { showErrorToast, showInfoToast } from '~/modules/ui/primitives/toaster';
import { assertUnreachable } from '~/utils/assertUnreachable';

import { requiredPhoneSchema, requiredStringSchema } from '~/utils/validation';

const mfaSetupSchema = z.object({
  phoneNumber: requiredPhoneSchema,
});

/**
 * MFADialogPhone is a setup dialog that allows a user to set their MFA phone
 * number. This only works for SMS OTP right now.
 */
function SetupForm(props: {
  onSuccess: (value: z.infer<typeof mfaSetupSchema>) => void;
}) {
  const form = useZodForm({
    schema: mfaSetupSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });

  const mfaPhoneMutation = viewerApi.mfa.setupMfa.useMutation({
    onSuccess: props.onSuccess,
    // we use noInvalidate here so the AppContext doesn't hiccup and send the
    // user back into verify mode.
    meta: {
      noInvalidate: true,
    },
  });

  return (
    <Form
      form={form}
      onSubmit={(values) => mfaPhoneMutation.mutateAsync(values)}
      data-test-id="mfa-setup-form"
    >
      <VStack gap="8">
        <PhoneInputField name="phoneNumber" label="Your phone number" />
        <SubmitButton className="mr-auto">Continue</SubmitButton>
      </VStack>
    </Form>
  );
}

const mfaVerifySchema = z.object({
  code: requiredStringSchema.min(6).max(6),
});

function VerifyForm() {
  const form = useZodForm({
    mode: 'onChange',

    schema: mfaVerifySchema,
    defaultValues: {
      code: '',
    },
  });
  const [shake, setShake] = useState(false);

  const mfaSubmit = viewerApi.mfa.verify.useMutation();
  const mfaSendNewCode = viewerApi.mfa.request.useMutation();

  const submitRef = useRef<HTMLButtonElement>(null);

  useLayoutEffect(
    function refocusAfterSubmit() {
      if (!form.formState.isSubmitting) {
        return;
      }

      return () => {
        // when submission stops
        form.setFocus('code');
      };
    },
    [form.formState.isSubmitting],
  );

  return (
    <Form
      form={form}
      onSubmit={async (values) => {
        const resetAndShake = () => {
          form.reset();

          setShake(true);
          setTimeout(() => setShake(false), 500);
        };
        const result = await mfaSubmit.mutateAsync(values).catch((error) => {
          // Handle unexpected errors
          resetAndShake();
          throw error;
        });

        switch (result.status) {
          case 'success': {
            break;
          }
          case 'invalid': {
            resetAndShake();
            showErrorToast('Invalid code', {
              description: 'Please try again.',
            });
            break;
          }
          case 'expired': {
            resetAndShake();

            showInfoToast('Code expired', {
              description: 'We are sending you a new code. Please try again.',
            });

            await mfaSendNewCode.mutateAsync();

            break;
          }
          default: {
            assertUnreachable(result.status);
          }
        }
      }}
    >
      <div className={cn(shake && 'animate-shake-lg')}>
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => {
            return (
              <InputOTP
                autoFocus
                id="mfa-authenticator"
                maxLength={6}
                {...field}
                disabled={form.formState.isSubmitting}
                onComplete={() => {
                  submitRef.current?.click();
                }}
              >
                <InputOTPGroup>
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTPGroup>
              </InputOTP>
            );
          }}
        />
      </div>
      <button
        ref={submitRef}
        type="submit"
        className="hidden"
        disabled={form.formState.isSubmitting}
      />
    </Form>
  );
}

function MFASetup() {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  if (!phoneNumber) {
    return (
      <>
        <AuthViewContentHeader>
          <AuthViewTitle>Set up two factor authentication</AuthViewTitle>
          <AuthViewDescription>
            Keep your account secure by setting up a phone number. Data or
            messaging rates may apply.
          </AuthViewDescription>
        </AuthViewContentHeader>

        <SetupForm
          onSuccess={(values) => {
            setPhoneNumber(values.phoneNumber as string);
          }}
        />
      </>
    );
  }

  return (
    <>
      <AuthViewContentHeader>
        <AuthViewTitle>Verify that it&apos;s you</AuthViewTitle>
        <AuthViewDescription>
          Please enter the code that we&apos;ve sent you via SMS
        </AuthViewDescription>
      </AuthViewContentHeader>
      <VerifyForm />
      <DeveloperMfaVerify />
    </>
  );
}

function useOnMountWhenWindowActive(callback: () => void) {
  const callbackRef = useEventCallback(callback);

  useOnMount(() => {
    if (document.hasFocus()) {
      callbackRef();
      return;
    }
    const onFocus = () => {
      callbackRef();
      window.removeEventListener('focus', onFocus);
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  });
}

export function MFA(props: {
  requiresSetup: boolean;
}) {
  return (
    <DefaultAuthView options={{ showFooterWordmark: false }}>
      {props.requiresSetup ? <MFASetup /> : <MFAVerify />}
    </DefaultAuthView>
  );
}

function MFAVerify() {
  const viewer = useAppViewer();
  const mfaSendCode = viewerApi.mfa.request.useMutation({
    onError(err) {
      showErrorToast('Error sending verification code', {
        description: err.message,
      });
    },
    onSuccess(data) {
      if (data?.attempts && data.attempts > 3) {
        // for people that continuously refresh the page
        showInfoToast('Verification code sent', {
          description:
            'Verification code have been sent to your phone. If you are having trouble receiving the code, please contact support.',
        });
      }
    },
  });

  useOnMountWhenWindowActive(() => {
    // Send a new code when the user comes back to the tab
    // It also send a code every time the user refresh the page
    mfaSendCode.mutate();
  });

  return (
    <>
      <AuthViewContentHeader>
        <AuthViewTitle>Verify that it&apos;s you</AuthViewTitle>
        <AuthViewDescription>
          {(() => {
            switch (viewer.mfaConfiguration.defaultChannel) {
              case 'SMS': {
                return "Please enter the code that we've sent you via SMS";
              }
              case 'TOTP': {
                return 'Please enter the code from your mobile authenticator';
              }
              case null: {
                return 'Multi factor authentication unavailable. Please contact support.';
              }
            }
          })()}
        </AuthViewDescription>
      </AuthViewContentHeader>
      <VerifyForm />
      <DeveloperMfaVerify />
    </>
  );
}
