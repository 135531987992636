import type { ComponentPropsWithoutRef } from 'react';
import { cn } from '~/modules/ui/cva';
import { HStack } from '~/modules/ui/primitives/stack';

function Wordmark({ className, ...props }: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 86 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('block h-5 shrink-0', className)}
      {...props}
    >
      <path d="M62.7969 20.9997L70.2057 2.99219H74.1159L81.5247 20.9997H78.3348L76.6112 16.6264H67.7104L65.9868 20.9997H62.7969ZM68.7136 14.0539H75.6079L72.1608 5.33316L68.7136 14.0539Z" />
      <path d="M45.7734 2.99219H48.6032V18.2986H59.4077V20.9997H45.7734V2.99219Z" />
      <path d="M31.3758 2.73618C36.5208 2.63328 40.8683 6.95508 40.7654 11.9972C40.8683 17.065 36.5208 21.3611 31.3758 21.2582C26.2308 21.3611 21.8833 17.065 21.9862 11.9972C21.8833 6.95508 26.2308 2.63328 31.3758 2.73618ZM31.3758 18.557C34.9258 18.557 37.807 15.8817 37.807 11.9972C37.807 8.1127 35.003 5.4373 31.3758 5.4373C27.7486 5.4373 24.9445 8.16415 24.9445 12.0229C24.9445 15.9331 27.8257 18.557 31.3758 18.557Z" />
      <path d="M19.2933 2.99219V5.69331H12.7334V20.9997H9.90363V5.69331H3.34375V2.99219H19.2933Z" />
    </svg>
  );
}

function PaymentsByTola({
  className,
  gap = '1',
  ...props
}: Omit<ComponentPropsWithoutRef<typeof HStack>, 'asChild' | 'children'>) {
  return (
    <HStack
      gap={gap}
      alignItems="center"
      className={cn(
        'whitespace-pre text-body text-foreground-secondary leading-4',
        '[&>svg]:inline-block [&>svg]:h-4',
        className,
      )}
      {...props}
    >
      Payments by <Wordmark />
    </HStack>
  );
}

export { Wordmark, PaymentsByTola };
