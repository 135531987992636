'use client';

import * as TogglePrimitive from '@radix-ui/react-toggle';
import * as React from 'react';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { VariantProps, cn, cva } from '~/modules/ui/cva';

const toggleVariants = cva({
  base: [
    focusRingClassNames,
    'flex items-center justify-center rounded-full text-foreground-secondary transition-colors',
    'hover:bg-surface-muted hover:text-foreground',
    'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:pointer-events-none disabled:opacity-50',
  ],
  variants: {
    variant: {
      default:
        'bg-transparent data-[state=on]:bg-surface-muted data-[state=on]:text-foreground',
      solid:
        'bg-surface-muted data-[state=on]:bg-surface-inverse data-[state=on]:text-foreground-inverse',
      outline:
        'border bg-transparent hover:bg-surface-muted hover:text-foreground',
    },
    size: {
      sm: 'h-6 px-2.5 text-body',
      md: 'h-8 px-3 text-body',
      lg: 'h-12 px-5 text-subtitle',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'md',
  },
});

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
