import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const MenuIcon = createIcon('MenuIcon', [
  [
    'path',
    {
      d: 'M4.75 6H19.25',
      key: 'p60tsq',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M4.75 18H19.25',
      key: 'apvc5f',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M4.75 12H19.25',
      key: 'b21an4',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default MenuIcon;
