import { useRouter } from 'next/router';
import { type ReactNode, useEffect, useState } from 'react';
import { Button } from '~/modules/ui/primitives/button';
import { Card } from '~/modules/ui/primitives/card';
import { DetailHelpArticle } from '~/modules/ui/primitives/detail-list';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetPanel,
  SheetTitle,
} from '~/modules/ui/primitives/sheet';
import { VStack } from '~/modules/ui/primitives/stack';
import type { MessageQueryParamValue } from '~/utils/urlMessageParam';
import {
  MESSAGE_QUERY_PARAM_KEY,
  MESSAGE_QUERY_PARAM_VALUES,
} from '~/utils/urlMessageParam';
import { omit } from '~/utils/utility';

function isValidValue(value: unknown): value is MessageQueryParamValue {
  let v = value;
  if (Array.isArray(v)) {
    v = v[0];
  }
  if (typeof v !== 'string') {
    return false;
  }
  return MESSAGE_QUERY_PARAM_VALUES.includes(v as MessageQueryParamValue);
}

export function AccountingUrlMessage() {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const value = router.query[MESSAGE_QUERY_PARAM_KEY];
  const content = isValidValue(value) ? getContent(value) : null;
  const onDismiss = (): void =>
    void router.replace(
      { query: omit(router.query, MESSAGE_QUERY_PARAM_KEY) },
      undefined,
      { shallow: true },
    );

  const onOpenChange = (open: boolean) => {
    if (!open) {
      onDismiss();
    }
    setIsOpen(open);
  };

  useEffect(() => {
    if (!isOpen && isValidValue(value)) {
      setIsOpen(true);
    }
  }, [value]);

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetPanel>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{content?.title}</SheetTitle>
          </SheetHeader>
          {content?.description}
        </SheetContent>
        <SheetFooter>
          <SheetClose asChild>
            <Button>Dismiss</Button>
          </SheetClose>
        </SheetFooter>
      </SheetPanel>
    </Sheet>
  );
}

type Content = {
  title: string;
  description: ReactNode;
};

function getContent(value: MessageQueryParamValue): Content {
  switch (value) {
    case 'quickbooks-success':
    case 'xero-success': {
      const [name, article] =
        value === 'quickbooks-success'
          ? ['QuickBooks', 8708558]
          : ['Xero', 8123282];
      return {
        title: `${name} Live-Sync is Connected`,
        description: (
          <VStack gap="1">
            <Card asChild>
              <VStack gap="2">
                <p className="text-body">
                  We are now importing your contacts, bills, and invoices to
                  Tola from {name}.
                </p>
                <p className="text-body">
                  This may take a few minutes, but you can continue to use Tola
                  while we sync data in the background.
                </p>
              </VStack>
            </Card>
            <DetailHelpArticle
              articleId={article}
              label="How Live-Sync works"
            />
          </VStack>
        ),
      };
    }
    case 'accounting-error': {
      return {
        title: 'Something went wrong',
        description: (
          <Card asChild>
            <VStack gap="2">
              <p className="text-body">
                We were unable to complete the connection to your accounting
                software.
              </p>
              <p className="text-body">
                Please try again, or contact support if the problem persists.
              </p>
            </VStack>
          </Card>
        ),
      };
    }
  }
}
