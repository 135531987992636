import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ArrowsIcon = createIcon('ArrowsIcon', [
  [
    'path',
    {
      d: 'M7 19.25L6.44253 19.7517C6.58476 19.9098 6.78739 20 7 20C7.21261 20 7.41524 19.9098 7.55747 19.7517L7 19.25ZM5.30747 16.2483C5.03038 15.9404 4.55616 15.9154 4.24828 16.1925C3.94039 16.4696 3.91544 16.9438 4.19253 17.2517L5.30747 16.2483ZM9.80747 17.2517C10.0846 16.9438 10.0596 16.4696 9.75172 16.1925C9.44384 15.9154 8.96962 15.9404 8.69253 16.2483L9.80747 17.2517ZM17 4.75L17.5575 4.24828C17.4152 4.09024 17.2126 4 17 4C16.7874 4 16.5848 4.09024 16.4425 4.24828L17 4.75ZM14.1925 6.74828C13.9154 7.05616 13.9404 7.53038 14.2483 7.80747C14.5562 8.08456 15.0304 8.05961 15.3075 7.75172L14.1925 6.74828ZM18.6925 7.75172C18.9696 8.05961 19.4438 8.08456 19.7517 7.80747C20.0596 7.53038 20.0846 7.05616 19.8075 6.74828L18.6925 7.75172ZM7.75 19.25V7.25H6.25V19.25H7.75ZM11.25 7.25V16.75H12.75V7.25H11.25ZM7.55747 18.7483L5.30747 16.2483L4.19253 17.2517L6.44253 19.7517L7.55747 18.7483ZM7.55747 19.7517L9.80747 17.2517L8.69253 16.2483L6.44253 18.7483L7.55747 19.7517ZM16.25 4.75V16.75H17.75V4.75H16.25ZM16.4425 4.24828L14.1925 6.74828L15.3075 7.75172L17.5575 5.25172L16.4425 4.24828ZM16.4425 5.25172L18.6925 7.75172L19.8075 6.74828L17.5575 4.24828L16.4425 5.25172ZM14.5 20C16.2949 20 17.75 18.5449 17.75 16.75H16.25C16.25 17.7165 15.4665 18.5 14.5 18.5V20ZM11.25 16.75C11.25 18.5449 12.7051 20 14.5 20V18.5C13.5335 18.5 12.75 17.7165 12.75 16.75H11.25ZM9.5 5.5C10.4665 5.5 11.25 6.2835 11.25 7.25H12.75C12.75 5.45507 11.2949 4 9.5 4V5.5ZM7.75 7.25C7.75 6.2835 8.5335 5.5 9.5 5.5V4C7.70507 4 6.25 5.45507 6.25 7.25H7.75Z',
      key: '1j1u67',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ArrowsIcon;
