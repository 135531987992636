import { useRouter } from 'next/router';
import { Route } from 'nextjs-routes';
import { useEffect, useState } from 'react';
import { useEventCallback } from '~/hooks/useEventCallback';
import { useViewer } from '~/hooks/useOrg';
import { createCtx } from '~/utils/context'; /**

/*
* This function determines which menu (or submenu) is associated with
* a given path.
 */
export function useGetMenuNameForPathname() {
  const viewer = useViewer();

  return (pathname: Route['pathname']) => {
    if (pathname === '/org/[slug]/contacts/[contactId]') {
      return 'contact';
    }

    if (
      pathname === '/org/[slug]/pay/[billId]' ||
      pathname === '/org/[slug]/pay/add'
    ) {
      return 'bill';
    }

    if (
      pathname === '/org/[slug]/get-paid/[billId]' ||
      pathname === '/org/[slug]/get-paid/add'
    ) {
      return 'invoice';
    }

    if (pathname === '/org/[slug]/get-paid/recurring/[recurringId]') {
      return 'recurring';
    }

    if (pathname === '/org/[slug]/get-paid/recurring/add') {
      return 'recurring-add';
    }

    // When the `recurring-invoices` feature flag is enabled, the `get-paid`
    // page is a submenu. If disabled, the get-paid page has the default, top-level menu.
    if (
      pathname.startsWith('/org/[slug]/get-paid') &&
      (viewer.enabledFeatureFlags.includes('11-05-recurring-invoices') ||
        viewer.enabledFeatureFlags.includes('30-10-awesome-ar'))
    ) {
      return 'get-paid';
    }

    if (
      pathname.startsWith('/org/[slug]/pay') ||
      pathname.startsWith('/org/[slug]/financing')
    ) {
      return 'pay';
    }

    if (pathname.startsWith('/org/[slug]/settings')) {
      return 'settings';
    }

    if (pathname.startsWith('/org/[slug]/workflows')) {
      return 'workflows';
    }

    return 'default';
  };
}
export type MenuName =
  | ReturnType<ReturnType<typeof useGetMenuNameForPathname>>
  | 'org-picker';

export function removeOrgResourceFromRoute(route: Route): Route {
  switch (route.pathname) {
    case '/org/[slug]/pay/[billId]': {
      return {
        pathname: `/org/[slug]/pay`,
        query: {
          slug: route.query.slug,
        },
      };
    }
    case '/org/[slug]/get-paid/[billId]': {
      return {
        pathname: `/org/[slug]/get-paid`,
        query: {
          slug: route.query.slug,
        },
      };
    }
    case '/org/[slug]/contacts/[contactId]': {
      return {
        pathname: `/org/[slug]/contacts`,
        query: {
          slug: route.query.slug,
        },
      };
    }
    case '/org/[slug]/get-paid/recurring/[recurringId]': {
      return {
        pathname: `/org/[slug]/get-paid/recurring`,
        query: {
          slug: route.query.slug,
        },
      };
    }
    default: {
      return route;
    }
  }
}

type Direction = 1 | -1;

function useMenuState(initialState: { name: MenuName }) {
  const [current, setCurrent] = useState<MenuName>(initialState.name);

  return {
    name: current as MenuName,
    setName: setCurrent,
  };
}
export type UseMenuState = ReturnType<typeof useMenuState>;

type Context = {
  direction: { current: Direction; set: (direction: Direction) => void };
  goTo: (step: MenuName, direction?: Direction) => void;
  onDismiss: () => void;
  routeMenuState: UseMenuState;
  visibleMenuState: UseMenuState;
};
const [useCtx, GlobalNavigationProvider] = createCtx<Context>();

export const useGlobalNavigationCtx = useCtx;

export function GlobalNavigationContext({
  children,
}: { children: React.ReactNode }) {
  const router = useRouter();
  const getMenuNameForPathname = useGetMenuNameForPathname();

  const initialState = getMenuNameForPathname(router.pathname);

  const [directionState, setDirectionState] = useState<Direction>(1);
  const visibleMenuState = useMenuState({ name: initialState });
  const routeMenuState = useMenuState({ name: initialState });

  const goTo: Context['goTo'] = useEventCallback((step, direction) => {
    if (direction) {
      setDirectionState(direction);
    }

    visibleMenuState.setName(step);
  });

  const onDismiss: Context['onDismiss'] = useEventCallback(() => {
    const expectedRouteMenu = getMenuNameForPathname(router.pathname);
    if (expectedRouteMenu !== visibleMenuState.name) {
      const dir = visibleMenuState.name === 'org-picker' ? -1 : 1;
      goTo(expectedRouteMenu, dir);
    }
  });

  useEffect(() => {
    const name = getMenuNameForPathname(router.pathname);
    routeMenuState.setName(name);

    if (name !== visibleMenuState.name) {
      goTo(name);
    }
  }, [router.pathname]);

  return (
    <GlobalNavigationProvider
      value={{
        visibleMenuState,
        routeMenuState,
        direction: {
          current: directionState,
          set: setDirectionState,
        },
        goTo,
        onDismiss,
      }}
    >
      {children}
    </GlobalNavigationProvider>
  );
}
