import { Slot } from '@radix-ui/react-slot';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';
import { type VariantProps, cn, cva } from '~/modules/ui/cva';

const stackVariants = cva({
  base: ['flex'],
  variants: {
    direction: {
      vertical: 'flex-col',
      horizontal: 'flex-row',
      'horizontal-reverse': 'flex-row-reverse',
      'vertical-reverse': 'flex-col-reverse',
    },
    wrap: {
      wrap: 'flex-wrap',
      nowrap: 'flex-nowrap',
      reverse: 'flex-wrap-reverse',
    },
    alignItems: {
      start: 'items-start',
      end: 'items-end',
      center: 'items-center',
      stretch: 'items-stretch',
      baseline: 'items-baseline',
    },
    alignContent: {
      start: 'content-start',
      end: 'content-end',
      baseline: 'content-baseline',
      center: 'content-center',
      stretch: 'content-stretch',
      around: 'content-around',
      between: 'content-between',
      evenly: 'content-evenly',
    },
    justifyContent: {
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      around: 'justify-around',
      between: 'justify-between',
      evenly: 'justify-evenly',
    },
    gap: {
      '0': 'gap-0',
      '0.5': 'gap-0.5',
      '1': 'gap-1',
      '1.5': 'gap-1.5',
      '2': 'gap-2',
      '2.5': 'gap-2.5',
      '3': 'gap-3',
      '4': 'gap-4',
      '5': 'gap-5',
      '6': 'gap-6',
      '7': 'gap-7',
      '8': 'gap-8',
      '9': 'gap-9',
      '10': 'gap-10',
      '12': 'gap-12',
      '16': 'gap-16',
      '20': 'gap-20',
    },
  },
});

type StackVariants = VariantProps<typeof stackVariants>;

export interface StackProps
  extends ComponentPropsWithoutRef<'div'>,
    StackVariants {
  asChild?: boolean;
}

const Stack = forwardRef<HTMLDivElement, StackProps>(function StackWithRef(
  {
    alignContent,
    alignItems,
    asChild,
    className,
    direction,
    gap,
    justifyContent,
    wrap,
    ...props
  },
  forwardedRef,
) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        stackVariants({
          direction,
          wrap,
          alignItems,
          alignContent,
          justifyContent,
          gap,
        }),
        className,
      )}
      ref={forwardedRef}
      {...props}
    />
  );
});

const HStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  function HStackWithRef(props, forwardedRef) {
    return <Stack direction="horizontal" ref={forwardedRef} {...props} />;
  },
);

const VStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  function VStackWithRef(props, forwardedRef) {
    return <Stack direction="vertical" ref={forwardedRef} {...props} />;
  },
);

export { Stack, HStack, VStack };
