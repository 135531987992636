import { m } from 'framer-motion';
import { cn } from '~/modules/ui/cva';

function LoadingIndicator({ className, ...props }: { className?: string }) {
  return (
    <div
      className={cn('grid size-10 place-items-center', className)}
      data-test-id="spinner"
      {...props}
    >
      <m.div
        className='size-2 rounded-full bg-current'
        animate={{
          y: ['-25%', '100%'],
          scaleY: [1, 1.3],
        }}
        transition={{
          duration: 0.4,
          ease: [0.7, 0, 1, 1],
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
    </div>
  );
}

export { LoadingIndicator };
