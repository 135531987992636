import Link from 'next/link';
import { Route } from 'nextjs-routes';
import type { ComponentPropsWithoutRef } from 'react';
import { useLogout } from '~/hooks/useLogout';
import { useAppViewerOrNull } from '~/modules/account/ui/AppContext';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { ErrorBoundary } from '~/modules/ui/components/error-boundary';
import { NoSSRSuspense } from '~/modules/ui/components/no-ssr-suspense';
import { Tooltip } from '~/modules/ui/components/tooltip';
import { cn } from '~/modules/ui/cva';
import { Card } from '~/modules/ui/primitives/card';
import { LogOutIcon } from '~/modules/ui/primitives/icon';
import { Lumiflex } from '~/modules/ui/primitives/lumiflex';
import { m } from '~/modules/ui/primitives/motion-provider';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { Toolbar, ToolbarButton } from '~/modules/ui/primitives/toolbar';
import { PaymentsByTola, Wordmark } from '~/modules/ui/primitives/wordmark';

export function AuthView({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <div className="bg-background">
      <HStack
        className={cn(
          'relative min-h-screen w-full p-0.5 text-foreground',
          className,
        )}
        gap="0.5"
        {...props}
      >
        {children}

        <AuthViewAside variant="ghost" data-test-screenshot-hide>
          <m.div
            className="absolute inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, delay: 0.5 }}
          >
            <Lumiflex />
          </m.div>
        </AuthViewAside>
      </HStack>
    </div>
  );
}

export function AuthViewColumn({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Card>) {
  return (
    <Card className={cn('relative flex-auto', className)} {...props}>
      {children}
    </Card>
  );
}

function AuthViewHeader() {
  const viewer = useAppViewerOrNull();
  const logout = useLogout();

  return (
    <HStack
      justifyContent="between"
      alignItems="center"
      className="pointer-events-none sticky top-4 z-30 md:top-6 [&>*]:pointer-events-auto"
    >
      <Link
        // We want to throw back to index which doesn't exist to redirect to where they should be
        // based on their current authentication status.
        href={'/' as unknown as Route}
        className={cn('self-center rounded-full', focusRingClassNames)}
      >
        <Wordmark />
      </Link>

      {viewer && (
        <Toolbar>
          <Tooltip
            disableHoverableContent
            delayDuration={0}
            side="bottom"
            align="center"
            content="Sign out"
          >
            <ToolbarButton intent="ghost" onClick={() => logout.logout()}>
              <LogOutIcon />
            </ToolbarButton>
          </Tooltip>
        </Toolbar>
      )}
    </HStack>
  );
}

type AuthViewOptions = {
  showFooterWordmark?: boolean;
};

const defaultAuthViewOptions: AuthViewOptions = {
  showFooterWordmark: true,
};

interface AuthViewContentProps
  extends ComponentPropsWithoutRef<typeof AuthViewColumn> {
  options?: AuthViewOptions;
}

export function AuthViewContent({
  className,
  children,
  options = defaultAuthViewOptions,
  ...props
}: AuthViewContentProps) {
  return (
    <AuthViewColumn
      className={cn(
        'flex min-h-full w-full shrink-0 flex-col gap-10 p-8 md:w-7/12 md:gap-12 lg:gap-16 lg:p-12',
        className,
      )}
      {...props}
    >
      <AuthViewHeader />
      <VStack className="max-w-md grow">{children}</VStack>
      <AuthViewFooter options={options} />
    </AuthViewColumn>
  );
}

export function AuthViewContentHeader(
  props: ComponentPropsWithoutRef<typeof VStack>,
) {
  return <VStack gap="3" {...props} />;
}

export function AuthViewTitle({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) {
  return <h1 className={cn('text-balance text-title', className)} {...props} />;
}

export function AuthViewDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={cn(
        'text-pretty text-body text-foreground-secondary',
        className,
      )}
      {...props}
    />
  );
}

function AuthViewAside({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof AuthViewColumn>) {
  return (
    <AuthViewColumn
      className={cn(
        'sticky top-0.5 hidden h-[calc(100vh-4px)] grow overflow-hidden md:block md:w-full',
        className,
      )}
      {...props}
    />
  );
}

interface AuthViewFooterProps extends ComponentPropsWithoutRef<'div'> {
  options?: AuthViewOptions;
}

export function AuthViewFooter({
  className,
  options = defaultAuthViewOptions,
  ...props
}: AuthViewFooterProps) {
  return (
    <div
      className={cn(
        'flex w-full flex-col-reverse items-start gap-y-5 py-2',
        'sm:flex-row sm:items-center sm:justify-between',
        'md:flex-col-reverse md:items-start',
        'lg:flex-row lg:items-center lg:justify-between',
        className,
      )}
      {...props}
    >
      {options?.showFooterWordmark ? <PaymentsByTola /> : <span aria-hidden />}

      <HStack alignItems="center" gap="6" className="sm:gap-8">
        <AuthViewFooterLink href="https://usetola.com/legal/terms-of-service">
          Terms of Service
        </AuthViewFooterLink>
        <AuthViewFooterLink href="https://usetola.com/legal/privacy-policy">
          Privacy policy
        </AuthViewFooterLink>
      </HStack>
    </div>
  );
}

export function AuthViewFooterLink({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={cn(
        focusRingClassNames,
        'focus-visible:rounded-sm focus-visible:ring-offset-background',
        'text-foreground-secondary leading-4 decoration-1 underline-offset-2 hover:underline',
        className,
      )}
      {...props}
    />
  );
}

interface DefaultAuthViewProps extends ComponentPropsWithoutRef<typeof VStack> {
  options?: AuthViewOptions;
}

export function DefaultAuthView({
  options = defaultAuthViewOptions,
  children,
  className,
  ...props
}: DefaultAuthViewProps) {
  return (
    <AuthView>
      <AuthViewContent options={options}>
        <VStack
          gap="10"
          className={cn('min-h-full', className)}
          justifyContent="center"
          {...props}
        >
          <ErrorBoundary>
            <NoSSRSuspense>{children}</NoSSRSuspense>
          </ErrorBoundary>
        </VStack>
      </AuthViewContent>
    </AuthView>
  );
}
